/* AboutUs.css */
.about-container {
    width: 80%;
    margin: 50px auto;
    background: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
  }
  
  .company-name {
    text-align: center;
    font-size: 32px;
    margin-bottom: 20px;
    color: #333;
  }
  
  .company-intro, .company-products {
    margin-bottom: 30px;
  }
  
  .company-intro h3, .company-products h3 {
    font-size: 24px;
    color: #444;
  }
  
  .company-intro p, .company-products p {
    font-size: 16px;
    line-height: 1.6;
    color: #666;
  }
  
  .company-details {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
  }
  
  .company-details th, .company-details td {
    border: 1px solid #ddd;
    padding: 10px;
    text-align: left;
  }
  
  .company-details th {
    background-color: #f2f2f2;
    color: #333;
  }
  
  .company-details ul {
    list-style-type: disc;
    margin: 0;
    padding-left: 20px;
  }
  /* Mobile devices */
@media (max-width: 480px) {
    .about-container {
        width: 95%;
        padding: 10px;
    }

    .company-name {
        font-size: 24px;
    }

    .company-intro h3, .company-products h3 {
        font-size: 20px;
    }

    .company-intro p, .company-products p {
        font-size: 12px;
    }

    .company-details th, .company-details td {
        padding: 6px;
    }
}