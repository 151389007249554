.carousel-container {
     width: 100%; /* Adjust the width as necessary */
    margin: 0 auto;
    margin-top: 0px;
    margin-bottom: 10px; 
    height: 100vh;/* Center the carousel */
  }
  
  .carousel-item img {
    height: auto;
    height: 100vh; /* Adjust the height as necessary */
    object-fit: cover;
  }
  .carousel-container {
    margin-bottom: 40px;
    background: rgba(0, 0, 0, 0.5); /* Optional: Add a background for better visibility */

  }
  
  .products-section {
    text-align: center;
    padding: 20px;
  }
  
  .products-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 40px;
  }
  
  .product-item {
    position: relative;
    width: 300px;
    height: 400px;
    overflow: hidden;
    border: 1px solid #ddd;
    border-radius: 8px;
    transition: transform 0.3s;
  }
  
  .product-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s;
  }
  
  .product-item h3 {
    margin-top: 10px;
    font-size: 1rem;
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    background: rgba(255, 255, 255, 0.7);
    padding: 5px 10px;
    border-radius: 5px;
    transition: background 0.3s;
  }
  
  .product-item:hover {
    transform: scale(1.05);
  }
  
  .product-item:hover img {
    transform: scale(1.1);
  }
  
  .product-item.view-all {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f8f9fa;
    position: relative;
    border: 2px solid transparent;
    animation: blinking-border 1s infinite;
  }
  
  @keyframes blinking-border {
    0% {
      border-color: transparent;
    }
    50% {
      border-color: #007bff;
    }
    100% {
      border-color: transparent;
    }
  }
  
  .view-all h3 {
    font-size: 1.2rem;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 32px;
  }
  

  
  .btn-primary {
    background-color: #007bff;
    border-color: #007bff;
    font-size: 32px;
  }
  
  .intro-section {
    text-align: center;
    padding: 40px;
    background-color: #f9f9f9;
    margin: 20px 0;
    border-radius: 8px;
    margin-bottom: 0px;
  }
  
  .intro-section h2 {
    font-size: 2rem;
    color: #333;
  }
  
  .intro-section p {
    color: #555;
    line-height: 1.6;
  }
  .products-section h2{
    font-size: 50px;
    color: #333;
    /* margin-bottom: 30px; */
    margin-top: 0px;
  }
  a{
    text-decoration: none;
    color: #333;
  }
  .carousel-caption {
    position: absolute;
    top: 70%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    padding: 10px;
    border-radius: 10px;
    font-weight: bold;
    color:rgb(26, 28, 30);
    font-size: 100px;
  }
  .products-section p{
    font-size: 1em;
    color: #555;
    line-height: 1.6;
    margin-bottom:20px;
  }